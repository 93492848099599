import React, { useEffect, useState } from 'react';

import { FunnelChart } from 'react-funnel-pipeline';
import 'react-funnel-pipeline/dist/index.css';
import useContacts from '../hooks/useContacts';

function Dashboard() {
  const [funnel, setFunnel] = useState(null);
  const { data: contacts, error, mutate } = useContacts({
    dateFrom: new Date('2021-08-01'),
  });

  function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  useEffect(() => {
    if (contacts?.length) {
      let groupedFunnel = groupBy(contacts, 'stage');
      setFunnel(groupedFunnel);
      console.log(groupedFunnel);
    }
  }, [contacts]);

  return (
    <div className="max-w-lg space-y-5">
      <p className="text-2xl font-bold">Kreeba (mes actual)</p>
      <FunnelChart
        data={[
          { name: 'Adquisición', value: funnel?.ACQUISITION?.length || 0 },
          { name: 'Validación', value: funnel?.VALIDATION?.length || 0 },
          { name: 'Perfilamiento', value: funnel?.PROFILING?.length || 0 },
          { name: 'Entregado', value: funnel?.DELIVERED?.length || 0 },
          { name: 'Contactado', value: funnel?.CONTACTED?.length || 0 },
          { name: 'Cita', value: funnel?.APPOINTMENT?.length || 0 },
          { name: 'Apartado', value: funnel?.PULLED_APART?.length || 0 },
          { name: 'Firmado', value: funnel?.SIGNED?.length || 0 },
        ]}
      />
    </div>
  );
}

export default Dashboard;
