import React from 'react';
import useSWR from 'swr';
import client from '../utils/client';

const fetcher = (url, query) =>
  client.get(url, { params: { ...query } }).then((res) => res.data.results);

const useContacts = (query = {}) => {
  const { data, error, mutate } = useSWR('/leadcontacts', (url) =>
    fetcher(url, query),
  );
  return { data, error, mutate };
};

export default useContacts;
